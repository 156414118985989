.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.table-rounded {
  border-radius: 10px;
  overflow: hidden;
}

.border-custom {
  border: 2px solid #e2e8f0;
}

.bg-yellow-50 {
  background-color: #fff7ed;
}

.bg-purple-50 {
  background-color: #faf5ff;
}

.bg-blue-50 {
  background-color: #eff6ff;
}

.bg-green-50 {
  background-color: #f0fdf4;
}

.border-700 {
  border-bottom: 1px solid #334155;
}

.border-200 {
  border-bottom: 1px solid #e2e8f0;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
}

.nav-link:hover {
  color: #42ba96 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #42ba96 !important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25);
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #c8ffc8;
}

.logo {
  filter: grayscale(100%) contrast(10%) sepia(60%) invert(100%);
  transition: filter .3s ease;
  opacity: .6;
}

.logo:hover {
  filter: none;
  opacity: 1;
}

.custom-opacity {
  opacity: .5;
}

.custom-opacity-title {
  opacity: .8;
}

.bottom-left-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 250px;
  height: auto;
}

.card-img-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.custom-rounded {
  border-radius: 1rem 1rem 1rem 1rem;
  overflow: hidden;
}

.custom-rounded-testimonial {
  border-radius: 1rem;
}

.custom-rounded-testimonial a {
  border-radius: 1rem 0 0 1rem;
  overflow: hidden;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #cbeae0 !important;
}

.text-center.ml-auto .btn.btn-sm.btn-link.text-white:hover {
  color: #cbeae0 !important;
}

.navbar-btn.btn.btn-pill.btn-sm.btn-outline-white.text-white.lift.ml-auto:hover {
  color: #389e7f !important;
}

@media (max-width: 991.98px) {
  .text-md-gray {
    color: #506690 !important;
  }
}

.border-width-2 {
  border-width: 2px !important;
}

.border-width-4 {
  border-width: 4px !important;
}

@media screen and (max-width: 768px) {
  .border-width-4 {
    border-width: 2px !important;
  }
}

.image-gradient-bg {
  background-color: white;
  background-image: radial-gradient(at 36% 28%, rgba(138, 254, 202, 0.54) 0, transparent 50%), radial-gradient(at 63% 71%, rgba(92, 252, 255, 0.38) 0, transparent 50%);
}

.image-gradient-bg-2 {
  background-color: white;
  background-image: radial-gradient(at 33% 37%, rgba(173, 138, 254, 0.54) 0, transparent 50%), radial-gradient(at 66% 65%, rgba(82, 180, 255, 0.38) 0, transparent 50%);
}

.image-gradient-bg-3 {
  background-color: white;
  background-image: radial-gradient(at 37% 30%, rgba(81, 189, 148, 0.54) 0, transparent 50%), radial-gradient(at 63% 71%, rgba(253, 160, 73, 0.45) 0, transparent 50%);
}

.gradient-shape {
  width: 200px;
  height: 200px;
  background-image: radial-gradient(circle, white, rgba(255, 255, 255, 0));
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.use-case-bg {
  background-color: #389e7f;
}

.custom-table td {
  padding-left: 1rem !important;
  height: 78px !important;
  max-height: 78px !important;
}

.custom-table thead th {
  height: 47.5px !important;
  vertical-align: middle !important;
  padding: 1rem !important;
  font-weight: 900 !important;
  line-height: 1.1rem !important;
  text-wrap: nowrap !important;
}

.custom-table thead th.custom-control {
  padding: 1rem !important;
}

.custom-table tbody td.custom-control {
  padding: 1rem !important;
}

.logo-scroll-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.logo-scroll {
  padding: 20px 0;
  position: relative;
}

.logo-scroll::before,
.logo-scroll::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  z-index: 2;
  pointer-events: none;
}

.logo-scroll::before {
  left: 0;
  background: linear-gradient(to right, white 20%, rgba(255, 255, 255, 0) 100%);
}

.logo-scroll::after {
  right: 0;
  background: linear-gradient(to left, white 20%, rgba(255, 255, 255, 0) 100%);
}

/* Light version */

.logo-scroll-wrapper-light {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.logo-scroll-light {
  padding: 20px 0;
  position: relative;
}

.logo-scroll-light::before,
.logo-scroll-light::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  z-index: 2;
  pointer-events: none;
}

.logo-scroll-light::before {
  left: 0;
  background: linear-gradient(to right, #F9FBFD 20%, rgba(255, 255, 255, 0) 100%);
}

.logo-scroll-light::after {
  right: 0;
  background: linear-gradient(to left, #F9FBFD 20%, rgba(255, 255, 255, 0) 100%);
}

.logos-container {
  display: -ms-flexbox;
  display: flex;
  animation: scroll 20s linear infinite;
  width: -moz-fit-content;
  width: fit-content;
}

.logo-item {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  padding: 0 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.logo-item img {
  max-height: 50px;
  width: auto;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-124%);
  }
}

.logo-scroll:hover .logos-container {
  animation-play-state: paused;
}

.logo-scroll-light:hover .logos-container {
  animation-play-state: paused;
}

@media (max-width: 768px) {
  .logo-item {
    padding: 0 15px;
  }

  .logo-item img {
    max-height: 40px;
  }
}